.access-nft-main-card {
  position: relative;
  width: 230px;

  @media screen and (max-width: 765px) {
    width: 160px;
  }
  .AccessNFTCard {
    width: 100%;
    background: linear-gradient(
      71.45deg,
      rgba(13, 11, 33, 0.54) 14.26%,
      rgba(86, 78, 206, 0.1242) 113.99%
    );
    border: 1px solid #564ece;
    display: flex;
    flex-direction: column;
    position: relative;

    .nft-img-wrapper {
      width: calc(100% - 6px);
      margin: 2px auto 0;
      @media screen and (max-width: 765px) {
        width: calc(100% - 4px);
      }
      .nft-img {
        transition: all 0.3s ease;
        width: 100%;
        aspect-ratio: 0.75;
        object-fit: cover;
        @media screen and (max-width: 765px) {
          height: 200px;
        }
      }

      .overlay-ribbon {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        margin-top: 15px;
        margin-left: 3px;
        svg {
          width: 120px;
        }
        @media screen and (max-width: 765px) {
          margin-left: 2px;
        }
      }

      .white-div {
        background-color: #ffffff;
        margin: 0 auto;
        font-weight: 700;
        font-size: 7px;
        text-align: center;
        @media screen and (max-width: 765px) {
          font-weight: 700;
          font-size: 2px;
        }
      }
      .line-bg {
        margin: 0 auto;
        position: relative;
        overflow: hidden;
        padding-bottom: 5px;
        .line-bg-img {
          background: rgba(255, 255, 255, 0.08);
          position: absolute;
          width: 100%;
          height: 100%;
        }
        .main-text-wrapper {
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 0 10px;
          position: relative;
          z-index: 1;
          .ge-force {
            width: max-content;
            max-width: 100%;
            background: rgba(255, 255, 255, 0.17);
            backdrop-filter: blur(1.37px);
            -webkit-backdrop-filter: blur(1.37px);
            border-radius: 3px;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: #ffffff;
            margin-top: 5px;
            padding: 5px;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .access-nft-main-title {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.01em;
            color: #ffffff;
            margin-top: 5px;
          }
          .access-nft-creator {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 0.01em;
            color: rgba(255, 255, 255, 0.49);
          }
          .access-nft-align {
            display: flex;
            justify-content: space-between;

            .ss-letter-logo {
              width: 25px;
              height: 30px;
            }

            .access-bg {
              background: linear-gradient(
                90.2deg,
                #564ece 22.33%,
                #3a32ad 100.76%,
                #3d34c9 100.76%
              );
              display: flex;
              align-items: center;
              justify-content: center;
              width: max-content;
              padding: 2px 5px;
              height: 18px;
              margin-top: 5px;
              svg {
                width: 10px;
                height: 10px;
              }
              .span-text {
                font-size: 10px;
                line-height: 10px;
                font-weight: 400;
                color: #ffffff;
                margin-top: 2px;
                text-transform: capitalize;
              }
            }
          }
        }
      }
    }
    .access-nft-bottom-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: auto;
      margin-top: 3px;
      span {
        svg {
          width: 33px;
          height: 37px;
          margin: 3px;
        }
      }

      .bottom-details-subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        color: #ffffff;
        margin: 10px 5px 10px 10px;
        @media screen and (max-width: 765px) {
          font-size: 4px;
          line-height: 4px;
        }
      }
    }
  }

  .accessNft-main-card-img {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 0.5;
    }

    .lock-icon-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 40%;
        height: auto;
        backdrop-filter: blur(2px);

        path {
          fill-opacity: 0.75;
        }
      }
    }

    .sold-out-ribbon {
      width: 150px;
      height: 150px;
      overflow: hidden;
      position: absolute;
      top: -1px;
      right: -1px;
      border-top: 3px solid transparent;
      border-right: 4px solid transparent;
      @media screen and (max-width: 765px) {
        border-right: 3px solid transparent;
      }
      span {
        position: absolute;
        display: block;
        width: 226px;
        background-color: #ff3f56;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.49em;
        color: #ffffff;
        left: -2px;
        top: 30px;
        transform: rotate(45deg);
        text-align: center;
      }

      &::before {
        top: 0;
        left: 0;
        border-top-color: transparent;
        border-right-color: transparent;
      }

      &::after {
        bottom: 0;
        right: 0;
        border-top-color: transparent;
        border-right-color: transparent;
      }
    }

    .onhover-accessnft-card-wrapper,
    .onhover-pending-wrapper {
      position: absolute;
      visibility: hidden;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: opacity 0.2s linear;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      .alert-white {
        width: 53px;
        height: 53px;
      }
      .click-for-details {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #ffffff;
        width: 70%;
        margin-top: 10px;
      }
    }
    .onhover-accessnft-card-wrapper {
      background: linear-gradient(
        162.24deg,
        rgba(157, 153, 208, 0.82) 3.31%,
        rgba(64, 51, 255, 0.82) 97.08%
      );
    }
    .onhover-pending-wrapper {
      background: rgba(165, 28, 230, 0.64);
    }
    .onhover-blue-processing {
      background: #564ece !important;
    }
    &:hover {
      .onhover-accessnft-card-wrapper,
      .onhover-pending-wrapper {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .bottom-card-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    .text-bottom {
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #ffffff;
      margin-top: 12px;
      min-height: 40px;
    }
    .name-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #564ece;
    }
    .left-info {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #ff3f56;
    }
    .buy-now-btn {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        linear-gradient(73.6deg, #85ffc4 2.11%, #5cc6ff 42.39%, #bc85ff 85.72%);
      border-radius: 19.2814px;
      font-weight: 500;
      font-size: 12px;
      color: #ffffff;
      padding: 2px 14px;
      border: none;
      &:hover {
        opacity: 0.7;
      }
      svg {
        margin-left: 4px;
        height: 11px;
        width: 12px;
      }
    }
    .button-add-to-cart {
      background: #564ece;
      border-radius: 20px;
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      line-height: 10px;
      padding: 8px 10px;
      border: 1px solid #564ece;
      &:hover {
        background: transparent;
        transition: 0.2s;
      }
    }

    .button-sold-out {
      background: #ff3f56;
      border-radius: 20px;
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      line-height: 10px;
      padding: 6px 10px;
      border: none;
      cursor: not-allowed;
    }
  }
}

.button-view-in-collection {
  background: #564ece;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 10px;
  border: 1px solid #564ece;
  &:hover {
    background: transparent;
    transition: 0.2s;
  }
}
.font-for-rarity-ribbon {
  font-size: 12px;
  color: #ffffff;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rarity-span-accessnft {
  svg {
    width: 15px;
    height: 15px;
  }
}
// access NFT selection card
.access-nft-selection-wrapper {
  background: transparent;
  border: 2px solid $starstack-blue-main;
  width: max-content;
  padding: 12px 14px;
  cursor: pointer;

  .text-bottom {
    min-height: 42px;
  }

  .form-check-input {
    background-color: rgba(86, 78, 206, 0.15);
    border-radius: 100%;
    width: 28px;
    height: 28px;
    margin: 0;
    border: none;
  }

  &.selected-access-nft {
    background: linear-gradient(65.56deg, #893af2 12.91%, #393394 50.41%);
    transition: 2s;

    .form-check-input {
      background-color: rgb(255, 255, 255);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23393394' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");

      & + .after-bg {
        color: $white-color;

        &::after {
          content: '';
          background: linear-gradient(65.56deg, #893af2 12.91%, #393394 50.41%);
          border-radius: 25px;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
        }
      }
    }
  }
}
// access NFT included in sNFT
.snft-included-accessNFT {
  .AccessNFTCard {
    background: rgba(86, 78, 206, 0.94);
  }
  .text-bottom {
    color: $black-color !important;
  }
}

// Collection card
.collections-card-wrapper {
  width: 213px;
  @media screen and (max-width: 765px) {
    width: 160px;
  }
  .imgs-wrapper {
    position: relative;
    .collection-main-img {
      background: linear-gradient(174.62deg, #5c4798 4.39%, #271360 97.46%);
      border-radius: 7px;
      width: 84%;
      aspect-ratio: 0.5;
      img {
        width: 100%;
        height: 100%;
        border-radius: 7px;
        object-fit: cover;
      }
    }
    .collection-bluebg-img {
      position: absolute;
      width: 35px;
      right: 0;
      bottom: 0;
      top: 0;
      @media screen and (max-width: 765px) {
        width: 26px;
      }
    }
  }
  .bottom-main {
    display: flex;
    flex-direction: column;
    width: 84%;
    .text-section {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      .collection-details-info {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #564ece;
        display: flex;
        align-items: center;
      }
    }
    .collection-main-title {
      font-size: 14px;
      line-height: 20px;
      height: 40px;
      color: #ffffff;
      margin-bottom: 8px;
    }
  }
}

// Bundles card
.Bundles-card-wrapper {
  width: 205px;
  @media screen and (max-width: 765px) {
    width: 160px;
  }
  .top-section {
    position: relative;
    width: 100%;
    overflow: hidden;
    .bundle-img-wrapper {
      width: 100%;
      border-radius: 7px;
      overflow: hidden;
      .Bundles-main-img {
        background: linear-gradient(174.62deg, #5c4798 4.39%, #271360 97.46%);
        border-radius: 7px;
        width: 100%;
        aspect-ratio: 0.58;
        position: relative;
        object-fit: cover;
      }
    }
    .onhover-bundle-card-wrapper {
      border-radius: 7px;
      z-index: 99;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: opacity 0.2s linear;
      background: linear-gradient(
        162.24deg,
        rgba(157, 153, 208, 0.82) 3.31%,
        rgba(64, 51, 255, 0.82) 97.08%
      );
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .alert-white {
        cursor: pointer;
        width: 53px;
        height: 53px;
      }
      .click-for-details {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #ffffff;
        padding: 15px 5px;
        text-align: center;
        cursor: pointer;
      }
    }

    .details-on-card {
      background: linear-gradient(
        0deg,
        #8367ee -23.58%,
        rgba(0, 0, 0, 0) 95.55%
      );
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      height: 50%;
      display: flex;
      align-items: flex-end;
      border-radius: 0 0 7px 7px;
      width: 100%;
      .text-of-details {
        text-align: left;
        width: 80%;
        margin-left: 10px;
        @media screen and (max-width: 765px) {
          width: 95%;
          margin-left: 5px;
        }
      }
      .bundle-title-on-card {
        color: #ffffff;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      }
      .bundle-divider-bottom {
        height: 2.11px;
        background: linear-gradient(
          89.97deg,
          #ffffff 26.08%,
          rgba(255, 255, 255, 0) 92.18%
        );

        width: 180px;
        margin-top: 9px;
      }
      .bucks-included {
        color: #ffffff;
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        margin-top: 10px;
        letter-spacing: 2%;
        text-transform: uppercase;
      }
    }
    &:hover {
      .Bundles-main-img {
        transition: all 0.5s ease;
        transform: scale(1.015);
      }
      .onhover-bundle-card-wrapper {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .rarity-section {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .rarity-bg {
      background-image: url('../../../../img/svg/BundleRarity.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 20px;
      height: 76px;
      margin-right: 15px;
      @media screen and (max-width: 765px) {
        background-image: url('../../../../img/svg/BundleRarity.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 15px;
        height: 60px;
        margin-right: 20px;
      }
      .text-rarity-bundle {
        rotate: 90deg;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        color: #ffffff;
        padding-left: 8px;
        @media screen and (max-width: 765px) {
          font-weight: 500;
          font-size: 6px;
        }
        .rarity-img {
          svg {
            height: 8px !important;
            width: 8px !important;
            @media screen and (max-width: 765px) {
              height: 6px !important;
              width: 6px !important;
            }
          }
        }
      }
    }
  }
  .bottom-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .text-section {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;
      .bundle-price {
        font-weight: 500;
        font-size: 12px;
        line-height: 25px;
        color: #8781e0;
        width: 69px;
        text-align: center;
        background: rgba(196, 196, 196, 0.1);
        border-radius: 26px;
        @media screen and (max-width: 765px) {
          font-size: 10px;
          width: 50px;
        }
      }
      .bundle-details-info {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #564ece;
        display: flex;
        align-items: center;
      }
    }
    .bundle-quantity {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 10px;
      color: #ff3f56;
    }
    .button-sold-out {
      background: #ff3f56;
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      line-height: 10px;
      padding: 10px;
      border: none;
      width: 70%;
      border-radius: 26px;
      border: 1px solid #ff3f56;
      cursor: not-allowed;
    }
    .bundles-main-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #ffffff;
      width: 100%;
      height: 40px;
      margin-bottom: 10px;
    }
  }
}

// Bucks-card
.buck-main-card-wrapper {
  width: 205px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: 765px) {
    width: 160px;
  }

  .buck-image-wrapper {
    overflow: hidden;
    position: relative;
    .buck-main-image {
      width: 100%;
      position: relative;
      object-fit: cover;
      transition: transform 0.2s;
      aspect-ratio: 0.5;
      .buck-video {
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        video {
          object-fit: cover;
        }
      }
      .audio-icon {
        position: absolute;
        top: 3px;
        right: 3px;
        cursor: pointer;
        z-index: 4;
        border-radius: 36px;
        background: #564ece;
        min-width: 148px;
        padding: 4px 10px;
        color: #fff;
        font-size: 13px;
        font-weight: 700;
        user-select: none;
        svg {
          margin-right: 4px;
          width: 20px;
          height: 20px;
          @media screen and (max-width: 765px) {
            height: 14px;
            width: 14px;
          }
        }
        &:hover {
          scale: 1.05;
        }
      }
      .adbuck-overlay {
        z-index: 3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }

    .sold-out-ribbon {
      width: 150px;
      height: 150px;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;

      span {
        position: absolute;
        display: block;
        width: 225px;
        background-color: #ff3f56;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.49em;
        color: #ffffff;
        left: 2px;
        top: 30px;
        transform: rotate(45deg);
        text-align: center;
      }

      &::before {
        top: 0;
        left: 0;
        border-top-color: transparent;
        border-right-color: transparent;
      }

      &::after {
        bottom: 0;
        right: 0;
        border-top-color: transparent;
        border-right-color: transparent;
      }
    }

    .active-adbuck-ribbon {
      width: 150px;
      height: 150px;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;

      span {
        position: absolute;
        display: block;
        width: 225px;
        background-color: #564ece;
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        color: #ffffff;
        left: 2px;
        top: 30px;
        transform: rotate(45deg);
        text-align: center;
      }
    }

    .onhover-buck-card-wrapper,
    .onhover-buck-card-wrapper-rejected {
      z-index: 4;
      position: absolute;
      visibility: hidden;
      opacity: 0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: opacity 0.2s linear;
      background: linear-gradient(
        162.24deg,
        rgba(157, 153, 208, 0.82) 3.31%,
        rgba(64, 51, 255, 0.82) 97.08%
      );
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
      .alert-white {
        cursor: pointer;
        width: 53px;
        height: 53px;
      }
      .click-for-details {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #ffffff;
        padding: 15px 5px;
        cursor: pointer;
      }
    }
    .onhover-buck-card-wrapper-rejected {
      background: linear-gradient(
        175.86deg,
        rgba(255, 112, 25, 0.82) 3.12%,
        rgba(255, 0, 14, 0.82) 96.41%
      );
      .reject-text {
        font-weight: 600;
        font-size: 18px;
      }

      .rejection-details {
        font-weight: 400;
        padding-top: 0.5em;
        text-align: center;
      }
    }

    &:hover {
      .buck-main-image &:not(.disabled-hover) {
        transform: scale(1.015);
      }
      .onhover-buck-card-wrapper,
      .onhover-buck-card-wrapper-rejected {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .buck-main-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #ffffff;
    margin-top: 12px;
    width: 100%;
    height: 40px;
    margin-bottom: 7px;
  }
  .buck-details-wrapper {
    display: flex;
    justify-content: space-between;
    .nickname-text {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #564ece;
    }
    .left-text {
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #ff3f56;
    }
  }
  .buck-details-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    .price-text {
      font-size: 16px;
      font-weight: 500;
      line-height: 12px;
      color: #564ece;
      margin-top: 7px;
      @media screen and (max-width: 765px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 10px;
      }
    }
    .button-add-to-cart {
      background: #564ece;
      width: 91.05px;
      height: 27px;
      border-radius: 20px;
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      line-height: 10px;
      border: 1px solid #564ece;
      &:hover {
        background: transparent;
        transition: 0.2s;
      }
      @media screen and (max-width: 765px) {
        width: 80px;
        height: 20px;
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
      }
    }
    .button-sold-out {
      background: #ff3f56;
      width: 92px;
      height: 27px;
      border-radius: 20px;
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      line-height: 10px;
      border: 1px solid #ff3f56;
      cursor: not-allowed;
      @media screen and (max-width: 765px) {
        width: 80px;
        height: 20px;
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
}

// star Card
.star-card-main {
  width: 208px;
  aspect-ratio: 0.71;
  background: #170f24;
  border-radius: 19px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  &:hover {
    .star-bg-image {
      transform: scale(1.015);
    }
    backdrop-filter: blur(4px);
    box-shadow: 2px 6px 18px -4px hsla(254, 54%, 46%, 0.501);
  }
  .star-bg-image {
    width: 100%;
    aspect-ratio: 1.74;
    border-radius: 19px 19px 0 0;
    object-fit: cover;
    position: absolute;
    top: 0;
  }
  .circle-img-center-wrapper {
    width: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    .circle-img-center {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      object-fit: cover;
      border: 6px solid #170f24;
      margin-top: -20px;
      background-color: #170f24;
    }
  }
  .star-bottom-section {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    .star-title {
      max-width: 90%;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;
      margin-top: 4px;
      .starmark {
        width: 11px;
        height: 14px;
      }
    }
    .button-see-profile {
      width: 152px;
      height: 43px;
      background: #564ece;
      border-radius: 21.5px;
      border: 1px solid #564ece;
      color: #ffffff;
      margin-bottom: 15px;
      margin-top: 10px;
      transition: all 0.3s ease;
      font-weight: 400;
      font-size: 16px;
      line-height: 12px;
      &:hover {
        background: transparent;
      }
    }
  }

  @media screen and (max-width: 630px) {
    width: 142px;
    border-radius: 14px;
    .star-bg-image {
      width: 100%;
    }
    .circle-img-center-wrapper {
      .circle-img-center {
        width: 86px;
        height: 85px;
        border: 2px solid #170f24;
        margin-top: -50px;
      }
    }
    .star-bottom-section {
      .star-title {
        font-size: 13.8654px;
        line-height: 29px;
        margin-top: 4px;
        .starmark {
          width: 10px;
          height: 11px;
        }
      }
      .button-see-profile {
        width: 117px;
        height: 33px;
        border-radius: 16px;
        margin-bottom: 15px;
        margin-top: 10px;
        font-weight: 400;
        font-size: 12.3248px;
        line-height: 9px;
      }
    }
  }
}

// starstake NFT card
.sNFT-card {
  width: 300px;
  background: #170f24;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  .bottom-stakehub-gradient {
    position: absolute;
    bottom: -20%;
    right: 2%;
    width: 157px;
    height: 160px;
    background: linear-gradient(180deg, #564ece 20.31%, #ff3f56 100%);
    opacity: 0.51;
    filter: blur(41.445px);
  }
  .card-image-container {
    overflow: hidden;
    position: relative;
    border-radius: inherit;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    .card-image {
      aspect-ratio: 1.23;
      width: 100%;
      object-fit: cover;
      transition: all 0.5s ease;
    }
    .sold-out-ribbon {
      width: 150px;
      height: 150px;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: 0;
      &.no-minimum-ribbon {
        height: 165px;
        width: 165px;
        span {
          top: 46px;
          background-color: $starstack-blue-main;
        }
      }
      span {
        position: absolute;
        display: block;
        width: 226px;
        background-color: #ff3f56;
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 16px;
        letter-spacing: 0.49em;
        color: #ffffff;
        left: 2px;
        top: 30px;
        transform: rotate(45deg);
        text-align: center;
      }

      &::before {
        top: 0;
        left: 0;
        border-top-color: transparent;
        border-right-color: transparent;
      }

      &::after {
        bottom: 0;
        right: 0;
        border-top-color: transparent;
        border-right-color: transparent;
      }
    }
  }
  .stakehub-level-fanstatus {
    padding: 6px 15px 15px;
    font-weight: normal;
    font-size: 18px;
    line-height: 38px;
    color: $starstack-blue-main;
    display: flex;
    justify-content: space-between;
    align-items: center;
    div[class^='level-wrapper'] {
      text-align: center;
      background-color: #1dd1a1;
      border-radius: 3.96px;
      padding: 8px 20px;
      line-height: 20px;
      color: #ffffff;
      &.level-wrapper-2 {
        background: #54a0ff;
      }
      &.level-wrapper-3 {
        background-color: #f368e0;
      }
      &.level-wrapper-4 {
        background-color: #5f27cd;
      }
      &.level-wrapper-5 {
        background-color: #ff9f43;
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 12px;
      line-height: 20px;
      div[class^='level-wrapper'] {
        border-radius: 2px;
        padding: 4px 10px;
      }
    }
    .fantype-img {
      img {
        width: 40px;
        height: 50px;
      }
    }
  }
  .owner-category-wrapper {
    position: relative;
    top: -45px;
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    .sNFT-owner-image-container {
      width: 56px;
      height: 56px;
      border: 2px solid #564ece;
      border-radius: 50%;
      overflow: hidden;
      .sNFT-owner-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .sNFT-fanscore-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(101, 98, 151, 0.38);
      border-radius: 15px;
      padding: 6px 12px;
      width: max-content;
      svg {
        width: 25px;
        height: 25px;
      }
      span {
        color: white;
        font-size: 12px;
        line-height: 17px;
      }
      p {
        color: white;
        margin: 0 !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-transform: capitalize;
      }
    }
    .sNFT-category-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #564ece;
      padding: 6px 12px;
      border-radius: 4px;
      width: max-content;

      p {
        color: white;
        margin: 0 !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-transform: capitalize;
      }
    }
  }
  .sNFT-title {
    margin-top: -40px;
    height: 50px;
    padding: 2px;
    padding-left: 15px;
    padding-right: 15px;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
  }
  .sNFT-details {
    display: flex;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between;
    p {
      color: #564ece;
    }
    svg {
      height: 25px;
      path {
        fill: #564ece;
      }
    }
  }
  .availability-container {
    // margin-top: -10px;
    display: flex;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    justify-content: space-between;
    .left-text {
      color: #ff3f56;
    }
  }
  .sNFT-buy-now {
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    position: relative;
    height: 40px;
    border-radius: 0 0 16px 16px;
    margin-top: -50px;
    background: #564ece;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  .sNFT-sold-out {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    opacity: 0;
    position: relative;
    height: 40px;
    border-radius: 0 0 16px 16px;
    top: -50px;
    background: #ff3f56;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    cursor: not-allowed;
  }
  .mysnft-details-link {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 25px;
    color: $white-color;
    opacity: 0.8;
    flex-wrap: wrap;
    span {
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    @media screen and (max-width: 767px) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
  &:hover {
    backdrop-filter: blur(4px);
    box-shadow: 0px 0px 34px #23204e;
    .card-image {
      transform: scale(1.02);
    }
    .sNFT-buy-now {
      opacity: 1;
      pointer-events: all;
    }
    .sNFT-sold-out {
      opacity: 1;
    }
  }
  @media screen and (max-width: 765px) {
    width: 160px;
    border-radius: 14px;

    &:hover {
      .card-image {
        transform: scale(1.02);
      }
      backdrop-filter: blur(4px);
      box-shadow: 6px 19px 62px -8px hsla(254, 54%, 46%, 0.501);
    }
    .owner-category-wrapper {
      position: relative;
      top: -25px;
      width: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px;

      .sNFT-owner-image-container {
        width: 35px;
        height: 35px;
      }
      .sNFT-category-container {
        padding: 4px;
        border-radius: 2px;
        svg {
          width: 12px;
          height: 12px;
        }
        p {
          font-size: 10px;
          line-height: 12px;
        }
      }
    }
    .sNFT-title {
      margin-top: -20px;
      height: 30px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
    .sNFT-title,
    .stakehub-level-fanstatus,
    .mysnft-details-link {
      padding: 5px;
    }
    .sNFT-details {
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      svg {
        height: 15px;
      }
    }
    .availability-container {
      // margin-top: -15px;
      p {
        font-weight: 400;
        font-size: 9px;
        line-height: 15px;
      }
    }
    .sNFT-buy-now {
      height: 30px;
      font-weight: 400;
      font-size: 10px;
      top: 0px;
    }
    .sNFT-sold-out {
      height: 30px;
      font-weight: 400;
      font-size: 10px;
      top: 0px;
    }
    &:hover {
      .sNFT-buy-now {
        opacity: 1;
        pointer-events: all;
      }
      .sNFT-sold-out {
        opacity: 1;
      }
    }
  }
}

// buttons and others
.divider-bottom {
  height: 3px;
  border-bottom: 1px solid #373737;
  margin-top: 4px;
}

.buttonaddtocart {
  background: #564ece;
  border-radius: 20px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  padding: 8px 10px;
  border: 1px solid #564ece;
  &:hover {
    background: transparent;
    transition: 0.2s;
  }
}

.collection-view-button {
  width: 80%;
  background: transparent;
  border: 1px solid #564ece;
  border-radius: 26px;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  color: #ffffff;
  padding: 15px 10px;
  @media screen and (max-width: 765px) {
    padding: 10px 5px;
  }
  &:hover {
    background: #564ece;
    transition: 0.2s;
  }
}

.bundle-view-button {
  width: 70%;
  background: #564ece;
  border-radius: 26px;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  color: #ffffff;
  padding: 10px;
  border: none;
  border: 1px solid #564ece;
  &:hover {
    background: transparent;
    transition: 0.2s;
  }
}

.two-line-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
}

.one-line-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
}

.overflow-wrap-word {
  overflow-wrap: anywhere;
}

.new-line-wrap {
  white-space: pre-wrap;
}

//sNFT main card
.sNFT-main-card-wrapper {
  min-width: 250px;
  min-height: 100%;

  .card {
    background: linear-gradient(
      71.45deg,
      rgba(13, 11, 33, 0.54) 14.26%,
      rgba(86, 78, 206, 0.1242) 113.99%
    );
    border: 2px solid $starstack-blue-main;
    box-sizing: border-box;
    backdrop-filter: blur(5.21937px);
    border-radius: 21.7765px;

    img {
      &.logoImg {
        width: 125px;
        height: 100%;
      }

      &.stampImg {
        width: 40px;
      }
    }

    .card-img-top {
      width: 100%;
      aspect-ratio: 0.986;
      border-radius: 1rem;
      background-color: $black2-color;
      object-fit: cover;
    }
  }

  h4.card-name {
    font-weight: normal;
    font-size: 15.6581px;
    line-height: 22px;
    color: $white-color;
  }

  h5 {
    font-weight: 300;
    font-size: 13.9183px;
    color: rgba(255, 255, 255, 0.28);
  }

  .card-addr {
    color: #7e7e7e;
    font-size: 11px;
    letter-spacing: 1px;
  }

  .categoryType {
    margin-top: 0.8rem;

    span {
      font-weight: 300;
      font-size: 15px;
      color: $white-color;
    }

    .text-start {
      label {
        display: block;
        font-weight: 300;
        font-size: 10px;
        line-height: 16px;
        color: $gray10-color;
      }
    }

    .text-end {
      text-align: right !important;

      label {
        display: block;
        font-weight: 300;
        font-size: 10px;
        color: $gray10-color;
      }
    }
  }
}
